import { useState, lazy, Suspense, memo, useEffect } from 'react'
import cx from 'classnames'
import useStyles from './navigation.styles'
import NavigationSkeleton from './NavigationSkeleton'
import CustomNavLink from './CustomNavLink'
import Icon from '../../../ui/components/Icon'
import { useTheme } from '@material-ui/core'
import { CustomTheme } from '../../CustomTheme/types'
import { useLocation } from 'react-router-dom'
import useGTMTracking from '../../../hooks/useGTMTracking'
import ModalPortals from '../ModalPortals/ModalPortals'
import SearchModal from '../../../context/SearchModal/SearchModal'
import { isSortAndFiltersEnabled } from 'src/business'

interface NavigationProps {
  isLoading: boolean,
  items: [{
    name: string,
    to: string,
    onClick: Function,
    selected: boolean,
    disabled: boolean,
  }],
}

const LazySearchModal = lazy(() => import('../../../context/SearchModal/SearchModal'))


const Navigation = ({ items, isLoading }: NavigationProps) => {
  const classes = useStyles()
  const theme = useTheme<CustomTheme>()
  const { pushDataLayer } = useGTMTracking()
  const [isModalOpen, setIsModalOpen] = useState(false)
  
  const openSearchModal = () => {
    pushDataLayer({
      'event': 'sort_filter_interaction',
      'interaction_type': 'click',
      'element_type': 'sub_nav',
      'element_layout': 'button',
      'element_name': 'sort_filter_open', 
      'element_id': 'subnav_search_modal_trigger',
      'element_position': {  
        'index': 1,
        'total': items.length
        },
        'element_text': 'null',
        'element_redirect_url': '/search',
    })
  }

  const handleOpenSearchModal = () => {
    openSearchModal()
    setIsModalOpen(true)
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
  })


  return (
    <>
      <ul id="subNavigation" className={classes.navigation}>
        {(theme.custom.searchModal?.active || isSortAndFiltersEnabled()) && (
          <li
            className={classes.searchItem}
            onClick={() => handleOpenSearchModal()}
          >
            <Icon name="search-alt" className={classes.searchIcon} />
          </li>
        )}
        {items.map(navigationItem => (
          <li className={classes.navigationItem} key={navigationItem.name}>
            {isLoading ? (
              <NavigationSkeleton />
            ) : (
              <CustomNavLink
                to={navigationItem.to}
                className={({ isActive }) =>
                  cx(
                    classes.navigationLink,
                    (navigationItem.selected || isActive) &&
                      classes.navigationLinkSelected,
                    navigationItem.disabled && classes.navigationLinkDisabled,
                  )
                }
                id={`navigation-button-${navigationItem.name
                  .toLocaleLowerCase()
                  .split(' ')
                  .join('-')}`}
              >
                <span className={classes.navListText}>
                  {navigationItem.name}
                </span>
              </CustomNavLink>
            )}
          </li>
        ))}
      </ul>

      {isModalOpen && (theme.custom.searchModal?.active || isSortAndFiltersEnabled()) && (
        <ModalPortals>
          <div
            className={classes.searchModal}
          >
            <Suspense fallback={null}> {/*TO DO: add fallback component */} 
            <LazySearchModal setIsModalOpen={setIsModalOpen} />
            </Suspense>
          </div>
        </ModalPortals>
      )}
    </>
  )
}

export default Navigation
