import Env from '@env';
import { isVipEnabled } from '../config'
import parseEnvBoolean from '../config/parseEnvBoolean'
import { formatMessage } from '../i18n'
import { toKebabCase } from '../libs/utils'

export const VIEW_MORE_LINK_THRESHOLD = 10
export const MAX_GAMES_CAROUSEL = 26
// TO-DO: THIS ENV IS SET TO 0 TO BRING ALL GAMES FROM API, CHECK THIS BEHAVIOUR
export const MAX_LIVE_CASINO_GAMES_CAROUSEL = 0

export const extendCategory = category => {
  if (!category.hasOwnProperty('slug')) {
    Object.defineProperty(category, 'slug', {
      get: function () {
        return toKebabCase(this.CategoryName)
      },
    })
  }
  return category
}

// category links
export const LIVE_CASINO_DISPLAY_ORDER = 3.5

const LIVE_CASINO_LINK = {
  name: formatMessage('category:live-casino'),
  icon: 'live-casino',
  to: '/games/live-casino',
}

const getCategoryLink = category => ({
  name: category.CategoryName,
  icon: category.slug,
  to: `/games/${category.slug}`,
})

export const buildCategories = categories => {
  return categories.map(category => ({
    icon: category?.Icon || '',
    iconUrl: category?.IconUrl || '',
    name: category.Name,
    to: `${category.Url}`,
  }))
}

export const buildCategoryLinks = categories => {
  const liveCasinoIndex = categories.findIndex(
    category => category.DisplayOrder >= LIVE_CASINO_DISPLAY_ORDER,
  )

  const categoryLinks = categories.map(getCategoryLink)

  return categoryLinks
    .slice(0, liveCasinoIndex)
    .concat(LIVE_CASINO_LINK)
    .concat(categoryLinks.slice(liveCasinoIndex))
}

// game sorting

const sortGamesByDisplayOrder = (game1, game2) =>
  game1.DisplayOrderCategory === game2.DisplayOrderCategory
    ? game1.DisplayOrder - game2.DisplayOrder
    : game1.DisplayOrderCategory - game2.DisplayOrderCategory

const sortGamesByIsNewOrder = (game1, game2) =>
  game1.IsNewOrder - game2.IsNewOrder

const sortGamesByPopularOrder = (game1, game2) =>
  game1.PopularOrder - game2.PopularOrder

/**
 * getCategoryGames - Gets all games from a category and its subcategories
 * sorted according to their display order
 * NOTE games are sorted only within a category or subcategory
 * @param {Object} category - category from which to get games
 * @return {Array} an array containing all games in {category} and its
 * subcategories
 */
export const getCategoryGames = category => {
  const games = category.Games.sort(sortGamesByDisplayOrder)

  if (category.SubCategories) {
    return category.SubCategories.reduce(
      (acc, subcategory) =>
        acc.concat(subcategory.Games.sort(sortGamesByDisplayOrder)),
      games,
    )
  }

  return games
}

/**
 * getNewGames - Gets new games of a set of categories
 * @param {Array} categories - categories from which to extract new games
 * @return {Array} an array of new games from all categories, sorted according
 * to their `IsNewOrder` property
 */
export const getNewGames = categories => {
  if (!categories || categories.length === 0) {
    return []
  }

  const games = categories.reduce((acc, category) => {
    let result = acc
    if (category.NewGames) {
      result = result.concat(category.NewGames)
    }
    if (category.SubCategories) {
      result = result.concat(getNewGames(category.SubCategories))
    }
    return result
  }, [])
  return games.sort(sortGamesByIsNewOrder)
}

/**
 * getPopularGames - Gets popular games of a set of categories
 * NOTE subcategories do not have popular games
 * @param {Array} categories - categories from which to extract popular games
 * @return {Array} an array of new games from all categories, sorted according
 * to their `PopularOrder` property
 */
export const getPopularGames = categories => {
  if (!categories || categories.length === 0) {
    return []
  }

  const games = categories.reduce((acc, category) => {
    let result = acc
    if (category.Popular) {
      result = result.concat(category.Popular)
    }
    if (category.SubCategories) {
      result = result.concat(getPopularGames(category.SubCategories))
    }
    return result
  }, [])

  return games.sort(sortGamesByPopularOrder)
}

export const getCategory = (slug, categories) => {
  if (!categories || categories.length === 0) {
    return []
  }

  const selectedCategory = categories.filter(
    category => category.CategoryName === slug,
  )

  if (selectedCategory.length === 0) {
    return []
  }

  // todo: clean up the games array
  const games = selectedCategory[0]?.Games.filter(game => {
    return (
      game.GameID !== 0 && game.ExtGameID !== 0 && game.GameName !== undefined
    )
  })

  return games || []
}

export const removeGroupCategory = (keys, categories) => {
  // Check if categories is not an array or is empty, return an empty array
  if (!Array.isArray(categories) || categories.length === 0) {
    return []
  }

  const newCategories = categories.filter(
    category => !keys.includes(category.CategoryName),
  )

  // Check if newCategories is empty after filtering, return an empty array
  if (newCategories.length === 0) {
    return []
  }

  return newCategories
}

/**
 * getGameCount - Gets the total number of games in a category and all of its
 * subcategories
 * @param {Object} category - category from which to get the total number of
 * games
 * @return {Number} the total number of games in {category} and its
 * subcategories
 */
export const getGameCount = category => {
  const count = category.Games.length

  if (category.SubCategories) {
    return category.SubCategories.reduce(
      (acc, subcategory) => acc + subcategory.Games.length,
      count,
    )
  }

  return count
}

const IMAGE_BASE_URL = Env.get('VITE_IMAGE_BASE_URL')

export const buildGameImageUrl = imagePath => `${IMAGE_BASE_URL}${imagePath}`

const BONUS_TYPES = {
  FRB: 'FRB', // NOTE free fround bonus
  CSB: 'CSB', // NOTE cash bonus
}

/**
 * isDepositBonus - Check whether a bonus is a deposit bonus
 * TODO implement this once we know the value of the BonusType
 * @param {Object} bonus - A bonus
 * @return {Boolean} true if bonus is a deposit bonus, false otherwise
 */
export const isDepositBonus = bonus => bonus.BonusType === 'Deposit Bonus'

/**
 * isFreeRoundBonus - Check whether a bonus is a free round bonus
 * @param {Object} bonus - A bonus
 * @return {Boolean} true if bonus is a free round bonus, false otherwise
 */
export const isFreeRoundBonus = bonus =>
  (bonus?.BonusType || bonus?.bonusType) === BONUS_TYPES.FRB

/**
 * isCashBonus - Check whether a bonus is a cash bonus
 * @param {Object} bonus - A bonus
 * @return {Boolean} true if bonus is a cash bonus, false otherwise
 */
export const isCashBonus = bonus => bonus.BonusType === BONUS_TYPES.CSB

/**
 * Refer-a-friend link types
 * NOTE only facebook and twitter links are used in this app
 * @typedef {(1|2|3|4|5|6|7)} LinkType
 * @enum {LinkType}
 */
export const RAF_LINK_TYPES = {
  GOOGLE_PLUS: 7,
  TWITTER: 6,
  FACEBOOK: 5,
  CALL_CENTER: 4,
  WEBSITE: 3,
  EMAIL_WEB: 2,
  EMAIL_LINK: 1,
}

/**
 * Bonus types for referrals. Only casino supported, since we do not have other
 * sections (e.g. sportsbook)
 */
export const REFERRAL_BONUS_TYPES = {
  CASINO: 'CAS',
}

/**
 * getRafCode - Returns the RAF code associated with a specific channel
 * NOTE to share in social media (e.g. facebook, twitter) the LinkCode is used,
 * whereas to share manually, the ManualLink is used
 * NOTE ManualLink included in all CustomerRAFLinks should be the same as the
 * LinkCode for facebook (ReferralJoinType = 5)
 * @param {Object} data - RAF data, as provided by /refer-friends endpoint
 * @param {LinkType} [type] - The type of link to build, defaults to manual link
 */
const getRafCode = (data, type) => {
  switch (type) {
    case RAF_LINK_TYPES.TWITTER:
    case RAF_LINK_TYPES.FACEBOOK:
      return data.CustomerRAFLinks.find(link => link.ReferralJoinType === type)
        ?.LinkCode
    default:
      // NOTE manual links are supposed to have the same value as the link code
      // for facebook, however they are often null so we find the first link
      // including it
      return data.CustomerRAFLinks.find(link => link.LinkCode)?.LinkCode
  }
}

export const buildRafLink = (data, type) =>
  `${window.location.origin}?RAF=${getRafCode(data, type)}`

/**
 * getJackpotValue - Returns the maximum jackpot in a data structure
 * @see https://coretransaction.emberaservice.ag/VIGService.svc/livecasino-jackpot?format=JSON
 */
export const getJackpotValue = data => {
  if (!data || !data.details || !data.details.length) return 0
  return Math.max(...data.details.map(d => d.amount))
}

// Define if TIER is VIP
export const isTierVIP = tier => tier === 'TIERVIP'

export const EVENTS = {
  MARKETING: {
    OPTIN: {
      CLICK: 'MARKETING.OPTIN.CLICK',
      OPEN: 'MARKETING.OPTIN.OPEN',
    },
  },
}

export const getCookie = name => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length == 2) return parts.pop().split(';').shift()
  return null
}

export const isVipLevelsEnabled = () => {
  return getCookie('viplevels') !== null || isVipEnabled()
}

export const isVipSnackbarEnabled = () => {
  const vipSnackbarCookie = getCookie('vipsnackbar')
  return  vipSnackbarCookie === 'true' || parseEnvBoolean('VITE_VIP_SNACKBAR')
}

export const isVipAvatarEnabled = () => {
  const vipAvatarCookie = getCookie('vipavatar')
  return  vipAvatarCookie === 'true' || parseEnvBoolean('VITE_VIP_AVATAR')
}

export const isXPEnabled = () => {
  return parseEnvBoolean('VITE_XP_BALANCE')
}

export const isIntercomCookieEnabled = () => {
  const intercomSwitchCookie = getCookie('WEBSITES-intercom-switch')
  return intercomSwitchCookie === 'true'
}

export const isSortAndFiltersEnabled = () => {
  const sortAndFiltersCookie = getCookie('sort-and-filters')
  return sortAndFiltersCookie === 'true'
}
