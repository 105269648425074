import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box as Spacing, useTheme } from '@material-ui/core'

import NoResultsImage from '../../assets/icons/no-results.svg'

import GridSkeleton from '../../ui/components/GridSkeleton'
import Container from '../../ui/components/Container'
import SearchBar from '../../ui/components/SearchBar'
import EmptyMessage from '../../ui/components/EmptyMessage'
import Head from '../Head'
import CategoryGrid from './Grid'

import { getToken, useAuth } from '../../authentication'

import { useTranslations } from '../../i18n'

import useGraphyte from '../../hooks/useGraphyte/useGraphyte'
import { useEnvData } from '../../hooks/useEnvData'
import { useCardCategoriesByRoute } from '../../asyncData/bff/useUnifiedLaunch'
import {
  GameCategoryInterface,
  GamesInterface,
} from '../../shared/interfaces/gameInterfaces'
import useGTMTracking from '../../hooks/useGTMTracking'
import GameCarousel from '../Home/GameCarousel'
import { existKeyInJson } from '../../i18n/useTranslations'
import { CustomTheme } from '../../ui/CustomTheme/types'
import { isSortAndFiltersEnabled } from 'src/business'

interface GridsInterface {
  id: number
  name: string
  games: GamesInterface[]
  icon?: string
  slug: string
  displayType: string
}
const Category = () => {
  const formatMessage = useTranslations()
  const { slug } = useParams<{ slug: string }>()
  const { sendPageViewTrack } = useGraphyte()
  const { isGraphyteEnabled } = useEnvData()

  const token = getToken()
  const { username, logged } = useAuth()
  const customerId = logged && username ? username : null
  const { data, isLoading } = useCardCategoriesByRoute(slug, customerId, token)
  const [query, setQuery] = useState<string>('')
  const { pushDataLayer } = useGTMTracking()
  const [isPristine, setIsPristine] = useState(true)
  const theme = useTheme<CustomTheme>()
  const searchRegex = new RegExp(query, 'i')
  const category: GameCategoryInterface =
    data?.categories && data?.categories[0]

  useEffect(() => {
    if (isGraphyteEnabled) {
      if (category) {
        sendPageViewTrack({
          category: category.categoryUrl,
          name: category.categoryName,
        })
      }
    }
  }, [isGraphyteEnabled, category])

  useEffect(() => {
    if (query && !isPristine) {
      pushDataLayer({
        event: 'filter_applied',
      })
    }
  }, [isPristine])
  const onChange = e => {
    const newQuery = e.target.value
    setQuery(newQuery)
    setIsPristine(!newQuery)

    if (!newQuery) {
      pushDataLayer({
        event: 'filter_reset',
      })
    }
  }

  const onClear = () => {
    setQuery('')
    setIsPristine(true)
    pushDataLayer({
      event: 'filter_reset',
    })
  }

  const onBlur = () => {
    setIsPristine(true)
  }

  let count = 0
  const grids: GridsInterface[] = data?.categories?.length > 0 && data?.categories?.map(({cards, categoryId, categoryName, categoryUrl, categoryIconUrl, displayType}) => {
    const games = query ? cards?.filter(game => searchRegex.test(game.name)) : cards
    count += games?.length ?? 0
    return {
      id: categoryId,
      name: categoryName,
      slug: categoryUrl,
      icon: categoryIconUrl,
      displayType,
      games,
    }
  })
  const categoryName = category && category?.categoryName
  const subCat = grids && grids?.find(el => el.name === slug)
  const isEmpty = count === 0
  
  return (
    <Spacing pt={{ xxs: 1, sm: 2 }}>
      <Head
        title={
          existKeyInJson(`meta:title:category:${slug}`)
            ? formatMessage(`meta:title:category:${slug}`)
            : categoryName
        }
        description={formatMessage(`meta:description:category:${slug}`)}
      />
      <Container>
        { isLoading && <GridSkeleton /> }
        {
          (!theme.custom.searchModal?.active && !isSortAndFiltersEnabled()) &&
            <SearchBar
              id="search_category"
              placeholder={
                categoryName
                  ? formatMessage('action:search-category', {
                    category: subCat ? subCat?.name : categoryName,
                  })
                  : formatMessage('action:search')
              }
              value={query}
              onChange={onChange}
              onClear={onClear}
              onBlur={onBlur}
            />
        }
        {
          (!isLoading && isEmpty) && 
            <EmptyMessage
              image={<NoResultsImage />}
              title={
                query
                  ? formatMessage('category:empty-search-title')
                  : formatMessage('category:empty-title')
              }
              description={
                query
                  ? formatMessage('category:empty-search-hint')
                  : formatMessage('category:empty-hint')
              }
            />
        }
        {
          (!isLoading && !isEmpty) &&
            grids.map(({ id, name, games, slug, displayType, icon, }) => displayType === 'carrousel' ? 
              <GameCarousel
                key={id}
                name={name}
                icon={icon}
                slug={slug}
                games={games}
                hasBreakingContainer={true}
              /> : <CategoryGrid key={id} name={name} games={games} />
        )}
      </Container>
    </Spacing>
  )
}

export default Category